<template>


<b-row :key="componentKey">

    


      

      <b-col
        sm="12"
        md="12"
        lg="12"
        xl="12"
      >

     
            <b-row>

               
              <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px">

              <h4 class="font-weight-bolder" style="text-align: left !important; margin-bottom: 10px;">
                <feather-icon
                              icon="CheckSquareIcon"
                              size="21"
                              @click="refresh"
                              class="color_icon"
                              />
                Opciones de recargas de billetera
              
              </h4>
              </b-col>


                <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px; margin-bottom:10px">

                  <carousel   :autoplay="false"  :dots="false" :stagePadding="10" :nav="false" :items="3" :margin="5" :responsive="{0:{items:1,nav:false},700:{items:2,nav:false},770:{items:3,nav:false},1200:{items:4,nav:false},1300:{items:5,nav:false}}">

                              
                      <boton-recargas-tarjeta
                                :user-id="userId"
                                :token-auth="tokenAuth"
                              />

                       
                              <boton-recargas-banco
                                :user-id="userId"
                                :token-auth="tokenAuth"
                              />

                              <boton-recargasusdt
                                :user-id="userId"
                                :token-auth="tokenAuth"
                                :address="address"
                               :id-wallet="idWallet"
                              />



                      </carousel>

                  </b-col>
              

             

            </b-row>

    
       


       

      </b-col>





 

        <b-col
        cols="12"
        sm="12"
        md="12"
        lg="8"
        xl="8"
      
      >

      <b-card>



        
        <b-tabs
            class="row"
          content-class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-md-0"
          pills
          nav-wrapper-class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          nav-class="nav-left"
         align="center"
        >



          <b-tab active>

            <template #title>
              <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block">   
                  <feather-icon
                        icon="CreditCardIcon"
                        size="21"
                        class="color_icon"
                        /> Tarjetas de pagos</span>
              <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none">    
                <feather-icon
                        icon="CreditCardIcon"
                        size="21"
                        class="color_icon"
                        /> Tarjetas de pagos</span>
            </template>

            <listado-recargas
            :key="componentKeyListadoRecargas"
            :user-id="userId"
             :token-auth="tokenAuth"
          />
      

          </b-tab>

         


              <b-tab @click="listBanco">

                <template #title>


                  <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block">
                
                    <svg class="icons_colors4"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 33.646 33.646"><g><path d="M18.008 9.985c.14.094.207.285.207.577.0.532-.312.799-.933.799h-.14V9.844c.439.0.727.0470000000000006.866.141zM15.492 8.25c0 .476.285.714.854.714.006.0.055.002.144.008V7.568l-.14.005C15.779 7.571 15.492 7.798 15.492 8.25zM27.99 26.906h3.666v3H1.99v-3h3.666V14.741H0L16.823 3.74l16.823 11.001H27.99V26.906zM15.323 14.741H8.656v12.165h6.667V14.741zm2.021-2.557c.685.0 1.164-.121 1.438-.365.273-.243.412-.669.412-1.273.0-.562-.126-.947-.378-1.158-.253-.211-.737-.336-1.456-.377l-.217-.008V7.571h.15c.531.0.795.212.795.636l.006.104h.936V8.178c0-.562-.135-.946-.398-1.153-.269-.206-.764-.31-1.486-.31V6.157h-.652v.558c-.758.0-1.275.107-1.557.324-.279.215-.42.616-.42 1.202.0.604.14 1.019.418 1.238.277.22.799.329 1.559.329v1.552l-.143-.004c-.392.0-.643-.052-.756-.153-.11-.102-.168-.329-.168-.682v-.1h-.967l-.004.194c0 .578.139.983.416 1.218.276.232.756.35 1.438.35l.184.005v.641h.652v-.641L17.344 12.184zm7.646 2.557h-6.667v12.165h6.667V14.741z"/></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>

                    Transferencia bancaria</span>
                  <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none">
                    <svg class="icons_colors4"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 33.646 33.646"><g><path d="M18.008 9.985c.14.094.207.285.207.577.0.532-.312.799-.933.799h-.14V9.844c.439.0.727.0470000000000006.866.141zM15.492 8.25c0 .476.285.714.854.714.006.0.055.002.144.008V7.568l-.14.005C15.779 7.571 15.492 7.798 15.492 8.25zM27.99 26.906h3.666v3H1.99v-3h3.666V14.741H0L16.823 3.74l16.823 11.001H27.99V26.906zM15.323 14.741H8.656v12.165h6.667V14.741zm2.021-2.557c.685.0 1.164-.121 1.438-.365.273-.243.412-.669.412-1.273.0-.562-.126-.947-.378-1.158-.253-.211-.737-.336-1.456-.377l-.217-.008V7.571h.15c.531.0.795.212.795.636l.006.104h.936V8.178c0-.562-.135-.946-.398-1.153-.269-.206-.764-.31-1.486-.31V6.157h-.652v.558c-.758.0-1.275.107-1.557.324-.279.215-.42.616-.42 1.202.0.604.14 1.019.418 1.238.277.22.799.329 1.559.329v1.552l-.143-.004c-.392.0-.643-.052-.756-.153-.11-.102-.168-.329-.168-.682v-.1h-.967l-.004.194c0 .578.139.983.416 1.218.276.232.756.35 1.438.35l.184.005v.641h.652v-.641L17.344 12.184zm7.646 2.557h-6.667v12.165h6.667V14.741z"/></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>

                    Transferencia bancaria</span>
                </template>

                <listado-banco
                  v-if="ifBanco"
                  :key="componentKeyListadoBanco"
                  :user-id="userId"
                  :token-auth="tokenAuth"
                />

                </b-tab>

        </b-tabs>

        


        </b-card>

      
      </b-col>

      <b-col
        cols="12"
            sm="12"
            md="12"
            lg="4"
            xl="4"
            >

            <b-card>

           
          

            <h4 class="font-weight-bolder" style="text-align: center !important;">
              <feather-icon
                            icon="LayoutIcon"
                            size="21"
                            @click="refresh"
                            class="color_icon"
                            />

              Calculadora de recargas
            </h4>
            <hr style="margin:10px">

                  <calculadora-recargas
                :user-id="userId"
                :token-auth="tokenAuth"
              />


            </b-card>

            </b-col>





</b-row>



</template>

<script>
import {
  BButton,VBPopover, BRow, BCol, BCard,BTabs, BTab,BAlert, BAvatar
} from 'bootstrap-vue'
import carousel from 'vue-owl-carousel'
import ListadoRecargas from './ListadoRecargas.vue'
import ListadoWester from './ListadoWester.vue'
import ListadoPaypal from './ListadoPaypal.vue'
import ListadoBanco from './ListadoBanco.vue'
import CalculadoraRecargas from './CalculadoraRecargas.vue'


import BotonRecargasusdt from './BotonRecargasusdt.vue'
import BotonRecargaWester from './BotonRecargaWester.vue'
import BotonRecargasPaypal from './BotonRecargasPaypal.vue'
import BotonRecargasBanco from './BotonRecargasBanco.vue'
import BotonRecargasTarjeta from './BotonRecargasTarjeta.vue'
export default {
  components: {
    BButton,
    BAlert,
    BAvatar,
    ListadoBanco,
    BRow,
    ListadoPaypal,
    BotonRecargasusdt,
    BotonRecargasTarjeta,
    BotonRecargasBanco,
    BCol,
    BotonRecargasPaypal,
    BotonRecargaWester,
    BCard,
    BTabs, BTab,
    CalculadoraRecargas,
    ListadoRecargas,
    ListadoWester,
    carousel
  },
    directives: {
    'b-popover': VBPopover,
  },
  data() {

    const datos_json = JSON.parse(this.$userGlobal)

 

    return {
      tokenAuth: this.$tokenGlobal,
       userId:datos_json.userId,
       idWallet:datos_json.idWalletUSDT,
      address:datos_json.addressUSDT,
  

       ifWester:false,
       ifPayPal:false,
       ifBanco:false,
      

       componentKeyListadoRecargas:0,
       componentKeyListadoWester:0,
       componentKeyListadoPayPal:0,
       componentKeyListadoBanco:0,
      

       componentKey:0,

     


       idPaypal:"",

    }
  },
  computed: {

  },
  watch: {

  },
  activated() {

this.$eventBus.$emit('reiniciarMenu1')
 this.$eventBus.$emit('reiniciarMenu2')


},
  mounted() {





     
    document.title = 'EQCoop - Recargar billetera'

        this.$eventBus.$on('reiniciarListadoRecargas', () => {
      this.componentKeyListadoRecargas += 1
    })



    

    this.$eventBus.$on('reiniciarListadoBanco', () => {
      this.componentKeyListadoBanco += 1
    });

    this.$eventBus.$on('reiniciarListadoPayPal', () => {
      this.componentKeyListadoPayPal += 1
    });

    this.$eventBus.$on('reiniciarListadoWester', () => {
      this.componentKeyListadoWester += 1
    });







    
    

    this.$eventBus.$on('reiniciarRecargasWallet', () => {
      this.componentKey += 1
    })


    
    


   
  },
  methods: {



    refresh() {
      this.$eventBus.$emit('reiniciarRecargar')
    },
    listBanco(){
      this.ifBanco=true;
    },
    listPayPal(){
      this.ifPayPal=true;
    },
    listWester(){
      this.ifWester=true;

    },


     

  },
}
</script>

<style lang="scss">







</style>
