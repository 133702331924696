<template>

    <div>



    <b-modal
      ref="modalWester"
      centered
      hide-footer
      @hidden="closeModalRecargasWester"
      size="sm"
      title="Recarga con Red Activa / Wester Union"
    >
      <div class="d-block text-center">

      <b-row v-if="showMontos">

     
          <b-row>

            <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <p class="textLabel" style="text-align: center;  margin-bottom: 5px;">
             Seleccione el monto a recargar (*):

                </p>
                </b-col>

          <b-col sm="12" md="12" lg="12" xl="12">

            <b-form
              autocomplete="off"
              @submit.prevent="onSubmitWester"
            >

            <b-row>

              <b-col cols="6" sm="6" md="6" lg="4" xl="4">

              <div class="inputGroup">
                <input id="2" :disabled="isDisabledCampos" v-model="mount" @change="onChangeMount($event)" value="2" type="radio"/>
                <label for="2">$2.00</label>
              </div>



              </b-col>

               <b-col cols="6" sm="6" md="6" lg="4" xl="4">

                <div class="inputGroup">
                  <input id="5" :disabled="isDisabledCampos" v-model="mount" @change="onChangeMount($event)" value="5" type="radio"/>
                  <label for="5">$5.00</label>
                </div>

  

            </b-col>

            <b-col cols="6" sm="6" md="6" lg="4" xl="4">

              <div class="inputGroup">
                <input id="10" :disabled="isDisabledCampos" v-model="mount" @change="onChangeMount($event)" value="10" type="radio"/>
                <label for="10">$10.00</label>
              </div>



              </b-col>

            <b-col cols="6" sm="6" md="6" lg="4" xl="4">

                <div class="inputGroup">
                  <input id="15" :disabled="isDisabledCampos" v-model="mount" @change="onChangeMount($event)" value="15" type="radio"/>
                  <label for="15">$15.00</label>
                </div>

  

            </b-col>

               <b-col cols="6" sm="6" md="6" lg="4" xl="4">

                <div class="inputGroup">
                  <input id="25" :disabled="isDisabledCampos" v-model="mount" @change="onChangeMount($event)" value="25" type="radio"/>
                  <label for="25">$25.00</label>
                </div>

  

            </b-col>

            <b-col cols="6" sm="6" md="6" lg="4" xl="4">

              <div class="inputGroup">
                <input id="30" :disabled="isDisabledCampos" v-model="mount" @change="onChangeMount($event)" value="30" type="radio"/>
                <label for="30">$30.00</label>
              </div>



              </b-col>

               <b-col cols="6" sm="6" md="6" lg="4" xl="4">

                <div class="inputGroup">
                  <input id="50" :disabled="isDisabledCampos" v-model="mount" @change="onChangeMount($event)" value="50" type="radio"/>
                  <label for="50">$50.00</label>
                </div>

  

            </b-col>

               <b-col cols="6" sm="6" md="6" lg="4" xl="4">

                <div class="inputGroup">
                  <input id="75" :disabled="isDisabledCampos" v-model="mount" @change="onChangeMount($event)" value="75" type="radio"/>
                  <label for="75">$75.00</label>
                </div>

  

            </b-col>

                 <b-col cols="6" sm="6" md="6" lg="4" xl="4">

                <div class="inputGroup">
                  <input id="100" :disabled="isDisabledCampos"  v-model="mount" @change="onChangeMount($event)" value="100" type="radio"/>
                  <label for="100">$100.00</label>
                </div>

  

            </b-col>

              <b-col
            sm="12"
            md="12"
            lg="12"
            xl="12"
          >

            <p
              class="textLabel"
              style="text-align: center;  margin-bottom: 5px;margin-top: 10px;"
            >
              Ingrese su código PIN (*): <feather-icon
                icon="HelpCircleIcon"
                size="16"
                  v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                title="Código PIN de seguridad"
                style="margin-right: 5px;cursor:pointer;"
                /> </p>

            <PincodeInput
              v-model="pin"
              placeholder="-"
              :length="6"
              :secure="true"
              required
              :autofocus="false"
              :disabled="isDisabledCampos"
            />

          </b-col>



              <b-col sm="12" md="12" lg="12" xl="12" align="center">

                 <b-button
                   id="btn_Generar"
                   block
                    ref="btn_Generar"
                                variant="primary"
                                 type="submit"
                                :disabled="isDisabledPay"
                              >

                               Generar código

                              </b-button>
              </b-col>

  
            </b-row>

</b-form>
       
</b-col>
            
 </b-row>  



<b-col sm="12" md="12" lg="12" xl="12" style="margin-top:10px; padding:0px" >

                    
<app-collapse
  accordion
  type="margin"
  class="address_card"
>

  <app-collapse-item title="Cómo recargar billetera con Red Activa / WU" icon="">
 
    <b-row>

      <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="display:flex">

      <b-avatar
                size="25"
                variant="light-primary"
                style="margin:auto;background: #00838c !important;color: white !important;">

                <span class="d-flex align-items-center">
                1

                </span>

                </b-avatar>


        </b-col>
        <b-col cols="10" sm="10" md="10" lg="10" xl="10" >
         
          <p style="text-align:left;margin:0px">Seleccione el monto de su recarga y genere un código de pago.</p>

        </b-col>

        <b-col cols="12" sm="12" md="12" lg="12" xl="12">
          <hr style="margin:10px">
        </b-col>


        <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="display:flex">
                              <b-avatar
                                    size="25"
                                    variant="light-primary"
                                    style="margin:auto;background: #00838c !important;color: white !important;">

                                    <span class="d-flex align-items-center">
                                     2

                                    </span>

                                    </b-avatar>
                       

                                </b-col>
                                <b-col cols="10" sm="10" md="10" lg="10" xl="10" >
         
                        
                            <p style="text-align: left;margin:0px">Presente el código generado en cualquier punto de <a style="color: #00838C;font-weight: bold;" href="https://redactiva.com/agencias/" target="_blank"> Red Activa / Wester Unión </a> y cancele el valor de su recarga.</p>


        </b-col>

        <b-col cols="12" sm="12" md="12" lg="12" xl="12">
          <hr style="margin:10px">
        </b-col>

        <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="display:flex">
                              <b-avatar
                                    size="25"
                                    variant="light-primary"
                                    style="margin:auto;background: #00838c !important;color: white !important;">

                                    <span class="d-flex align-items-center">
                                     3

                                    </span>

                                    </b-avatar>
                       

                      </b-col>
                      <b-col cols="10" sm="10" md="10" lg="10" xl="10" >
         

                            <p style="text-align: left;margin:0px">Su recarga se realizará automáticamente después de media hora de haber realizado el pago o puede confirmarlo manualmente desde el <b-link style="color: #00838C;font-weight: bold;" :to="{ name: 'recargar'}"  >listado de recargas con Red Activa / Wester Union</b-link>.</p>



      </b-col>
    </b-row>
  </app-collapse-item>

  </app-collapse>

  </b-col>


      </b-row>

      <b-row v-else>

        <b-col sm="12" md="12" lg="12" xl="12">
          <img  class="logoSVG"/>
        </b-col>



        <b-col sm="12" md="12" lg="12" xl="12" ref="printMe" class="background_download">

         
        <p style="text-align: center;font-size:14px;margin-bottom: 5px;">Su código para la recarga de ${{mount}} es: </p>

        <b-alert
                variant="secondary"
                show
                style="margin-bottom: 10px !important;"
              >
                <div class="alert-body">
                  
                  <p class="saldoDisponible" style="margin-bottom: 10px;">{{CodeGenerate}}</p>

                
                </div>
              </b-alert>

              <p style="text-align: center;font-size:14px;margin-bottom: 5px;">Debe realizar el pago antes del: <br> <b>{{DateExpiration}} </b></p>

        </b-col>

        <b-col cols="12" sm="12" md="12" lg="12" xl="12" align="center" style="margin-top: 10px; margin-bottom: 10px;">

         
                <b-button @click="descargar" variant="primary" :disabled="isDisabledDescargar" style="margin-right: 15px;">

                            <feather-icon
                        icon="DownloadIcon"
                        size="16"
                        style="margin-right: 10px;"
                        
                        /> 

                            Descargar

                            </b-button>

                            <b-button @click="shared" variant="outline-secondary" :disabled="isDisabledDescargar">

                                <feather-icon
                                icon="Share2Icon"
                                size="16"
                                style="margin-right: 10px;"


                                /> 

                                Compartir

                                </b-button>

                        
                </b-col>


          <b-col sm="12" md="12" lg="12" xl="12" >

       
              <app-collapse
                accordion
                type="margin"
              >

                <app-collapse-item title="¿Dónde puedo pagar mi recarga?" icon="InfoIcon">
                  <p style="margin: 0;">  Presente el código de pago generado en cualquier punto de <a style="color: #00838c;font-weight: bold;" href="https://redactiva.com/agencias/" target="_blank"> Red Activa / Wester Unión </a> para realizar el pago correspondiente.</p>

                </app-collapse-item>

                <app-collapse-item title="¿Hasta cuándo puedo pagar?" icon="InfoIcon">

                  <p style="margin: 0;">Ten en cuenta la fecha de vencimiento de este comprobante ya que no podrás utilizarlo si se encuentra vencido.</p>

  
                    </app-collapse-item>

                <app-collapse-item title="¿Qué debo hacer luego de pagar mi recarga?" icon="InfoIcon">
                  <p style="margin: 0;">Debes regresar a EQCoop y confirmar el pago de tu recarga desde el listado de recargas de Red Activa / Wester Union.</p>
                </app-collapse-item>

              </app-collapse>

          </b-col>


      </b-row>


        </div>


        </b-modal>
  
  
  </div>
  
  </template>
  
  <script>
  import {
    VBTooltip,VBPopover, BButton, BRow, BCol, BCard, BForm, BAlert, BAvatar,BLink
  } from 'bootstrap-vue'

  import PincodeInput from 'vue-pincode-input'
  import AppCollapse from '@core/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/app-collapse/AppCollapseItem.vue'
import domtoimage from "dom-to-image-more";
  
  export default {
    components: {
      BButton,
      BForm,
      BAvatar,
      BRow,
      BCol,
      BAlert,
      BLink,
      AppCollapseItem,
    AppCollapse,
    domtoimage,
      BCard,
      PincodeInput,
    },
      directives: {
            'b-popover': VBPopover,
      'b-tooltip': VBTooltip,
    },
    props: ['userId','tokenAuth'],
    data() {
  
                          
  
      return {



        isDisabledCampos:false,
       title:"",
       mount:0,
       isDisabledPay:true,
       showMontos:true,
       pin:"",
       DateTransaction:"",
       CodeGenerate:"",
       DateExpiration:"",
       output: null,
       isDisabledDescargar:false,
       yesRefresh:false

      
      }
    },
    computed: {
  
  
  
    },
    watch: {
  
  
  
    },
    mounted() {
  
  
        this.mount="";
        this.isDisabledCampos=false;
        this.pin="";
        this.isDisabledPay=true;
        this.showMontos=true;
        this.isDisabledDescargar=false;

        this.$refs.modalWester.show()
  
        
  
  
  
    },
    methods: {

        closeModalRecargasWester(){

     
         
            this.$eventBus.$emit('reiniciarRecargasWester');
    
          this.$eventBus.$emit('reiniciarRecargarWesterHome')

    },
        
    onChangeMount(event){

        this.mount = event.target.value;

            this.isDisabledPay=false;

        },
        onSubmitWester(event){
      event.preventDefault()

      if(this.mount > 0){

        
            const userId_json = {
              userId: this.userId, pin: this.pin, mount: this.mount
            }
            const user_string = JSON.stringify(userId_json)



            this.isDisabledPay = true
            this.isDisabledCampos = true

            const dataEncripted = this.$encryptWester.encrypt(user_string)



            document.getElementById('btn_Generar').innerHTML = '<img style="margin-left:10px; " src="/img/loading2.svg" alt="Loading" /> Generando código'

            this.$httpsWester.post('/red_activa/recharges/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
              this.isDisabledPay = false
              this.isDisabledCampos = false


              document.getElementById('btn_Generar').innerHTML = "Generar código";


              if (response.data.code == 200) {


                    this.$toast.success(response.data.message, {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                });
                
             
                  this.$eventBus.$emit('reiniciarListadoWester');

                  this.DateTransaction= response.data.DateTransaction;
                  this.CodeGenerate= response.data.CodeGenerate;
                  this.DateExpiration=response.data.DateExpiration;

                  this.showMontos=false;

          




              } else {
           

                this.$toast.error(response.data.message, {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })

                if (response.data.code == 401) {
                  
                  localStorage.removeItem('userData')

                  

                  
                  

                

                  
                  

                  // Redirect to login page
                  this.$router.push({ name: 'auth-login' })
                } else if (response.data.code == 502) {
                  this.dni = ''
                } else if (response.data.code == 503) {
                  this.pin = ''
                }else if(response.data.code == 406){
                  
                  this.$swal({
                            title: 'No tienes registrado una cédula/DNI a tu perfil',
                            text: `¿Deseas completar tu perfil ahora?`,
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Registrar',
                            cancelButtonText: 'No',
                            customClass: {
                              confirmButton: 'btn btn-primary',
                              cancelButton: 'btn btn-outline-secondary ml-1',
                            },
                            buttonsStyling: false,
                          }).then(result => {
                            if (result.value) {
                              this.$router.push({ name: 'perfil', hash: '#general' })
                
                            }
                          })
                }
              }
            }).catch(error => {
              this.$toast.error("Ocurrió un error inesperado", {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })
            })


            }else{

                  this.$toast.error("Por favor seleccione un monto", {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })

            }

    },
   async shared(){

        const node = this.$refs.printMe;

        const scale = 750 / node.offsetWidth;
    
  

           let base64= await domtoimage
                .toPng(node, {
                     quality: 1,
                    height: node.offsetHeight * scale,
                    width: node.offsetWidth * scale,
                    style: {
                        transform: "scale(" + scale + ")",
                        transformOrigin: "top left",
                        width: node.offsetWidth + "px",
                        height: node.offsetHeight + "px"
                    }})
                .then(function (dataUrl) {

                    return dataUrl;


         
                });


            fetch(base64).then(function (response) {
            return response.blob()
            }).then(function (blob) {
            let file = new File([blob], "Codigo.png", {type: 'image/png'});
            let filesArray = [file];
            if (navigator.canShare && navigator.canShare({files: filesArray})) {
                navigator.share({
                files: filesArray
                }).then(() => console.log('Successful share'))
                .catch((error) =>   this.$toast.error("Ocurrió un error inesperado", {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                }));
            }else{

                this.$toast.error("Lo sentimos, tu navegador no es compatible para compartir este contenido", {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                })

            }
            });




            },
  
    async descargar(){



            const node = this.$refs.printMe;


            const scale = 750 / node.offsetWidth;
    

            this.isDisabledDescargar=true;
        

           let base64= await domtoimage
                .toPng(node,{
                            quality: 1,
                    height: node.offsetHeight * scale,
                    width: node.offsetWidth * scale,
                    style: {
                    transform: "scale(" + scale + ")",
                    transformOrigin: "top left",
                    width: node.offsetWidth + "px",
                    height: node.offsetHeight + "px"
                    }})
                .then(function (dataUrl) {

                    return dataUrl;


         
                })
                .catch(function (error) {
                console.error("oops, something went wrong!", error);
                });

          
                var link = document.createElement("a");
                    link.download = this.CodeGenerate+".png";
                    link.href = base64;
                    link.click();
                    this.isDisabledDescargar=false;



            },
  
     
    }
  }
  </script>
  
  <style lang="scss">
  
  
  
  
  
  </style>
  