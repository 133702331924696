<template>

    <div>
    <address-wallet v-if="showWalletAddress" :key="componentKeyAddressWallet" :currency="currency" :address="address" :id-wallet="idWallet"  />
        
        <b-card  class="card_opciones" block @click="openUSDT">
    
                <b-row>
                    <b-col cols="3" sm="3" md="3" lg="12" xl="12" align="center" style="display: flex;" >
    
                    <b-avatar
                        size="40"
                        variant="light-primary"
                        style="margin:auto;">
    
                        <span class="d-flex align-items-center">
                            <feather-icon
                                    icon="TrelloIcon"
                                    size="25"
    
                                    class=" icon_opciones2"
                                    />
    
                        </span>
    
                        </b-avatar> 
    
    
                        </b-col>
    
                        <b-col cols="9" sm="9" md="9" lg="12" xl="12" >

                            <p class="p1_opciones">Con mi billetera USDT </p>  
                            <p class="p2_opciones">Fácil, rápido y sin comisiones </p>  
    
    
                        </b-col>
                </b-row>
    
                </b-card>
    
      </div>
      </template>
      
      <script>
      import {
        BButton, BRow, BCol, BCard,BAvatar
      } from 'bootstrap-vue'

      import AddressWallet from '../wallets/AddressWallet.vue'
        
    
      export default {
        components: {
            BButton,
            BAvatar,
        BRow,
        BCol,
        BCard,
        AddressWallet
        },
        directives: {
        
        },
        props: ['userId', 'tokenAuth','address','idWallet'],
        data() {
    
        
    
          return {
          
            currency:"USDT",
            showWalletAddress:false,
            componentKeyAddressWallet:100,
          
          }
        },
        computed: {
      
        },
        watch: {
      
      
        },
        mounted() {

    
        this.$eventBus.$on('reiniciarRecargasConUSDT', () => {
          this.componentKeyAddressWallet += 1
          this.showWalletAddress=false;
        });
    
        
          
        },
        methods: {
    
          openUSDT(){
            this.showWalletAddress=true;
          },
      
      
          
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
    
    
      </style>
      