<template>

<div>
        <modal-recarga-wester v-if="openModalWester" :key="componentKeyModalRecargaWester"  :user-id="userId" :token-auth="tokenAuth"  />
       
        <b-card id="redActiva" class="card_opciones" block @click="redActiva">

                <b-row>
                  <b-col cols="3" sm="3" md="3" lg="12" xl="12" align="center" style="display: flex;" >

                
                <b-avatar
                    size="40"
                    variant="light-primary"
                    style="margin:auto;">

                    <span class="d-flex align-items-center">
                        <feather-icon
                                icon="MapPinIcon"
                                size="25"

                                class=" icon_opciones2"
                                />

                    </span>

                    </b-avatar> 



                    

                    </b-col>

                    <b-col cols="9" sm="9" md="9" lg="12" xl="12" >

                        <p class="p1_opciones">Con puntos de recargas </p>  
                        <p class="p2_opciones">Red Activa / Wester Union </p>  


                    </b-col>
                </b-row>

                </b-card>
 
  </div>
  </template>
  
  <script>
  import {
    BButton, BRow, BCol, BCard, BAvatar
  } from 'bootstrap-vue'
  import ModalRecargaWester from './ModalRecargaWester.vue'

  export default {
    components: {
        BButton,
        BAvatar,
    BRow,
    BCol,
    BCard,
    ModalRecargaWester,

    },
    directives: {
    
    },
    props: ['userId', 'tokenAuth'],
    data() {

    

      return {
      
        openModalWester:false,
        isDisabled:false,
        componentKeyModalRecargaWester:0,
      }
    },
    computed: {
  
    },
    watch: {
  
  
    },
    mounted() {

        this.$eventBus.$on('reiniciarRecargasWester', () => {
      this.componentKeyModalRecargaWester += 1
      this.openModalWester=false;
    });
      
    },
    methods: {
  
        redActiva(){

this.openModalWester=true;



},
  
    },
  }
  </script>
  
  <style lang="scss">
  
  


  </style>
  