<template>

                    <b-row>

                        <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom: 10px;">
                                <p style="text-align: left;margin-bottom: 5px;"> Forma de recargar (*):</p>
                                <b-skeleton v-if="loading"  width="100%"  height="40px" style="margin-bottom:0px"></b-skeleton>

                            <b-form-select
                            v-else
                            @change="onChangeFormaRecarga()"
                                v-model="formaRecarga"
                                :options="options1"
                                />
                        </b-col>

                         <b-col cols="6" sm="6" md="6" lg="6" xl="6"  >
                               <p style="text-align: left;margin-bottom: 5px;"> Dinero a recargar (*):</p>
                               <b-skeleton  v-if="loading" width="100%" style="margin-top:10px;margin-bottom:0px" height="40px"></b-skeleton>
                            <b-form-select
                            v-else
                            @change="onChangeMount()"
                                v-model="mountRecarga"
                                :options="options2"
                                :disabled="isDisabledMount"
                                />
                        </b-col>

                         <b-col cols="6" sm="6" md="6" lg="6" xl="6"  >
                             <p style="text-align: left;margin-bottom: 5px;"> Cantidad acreditada: </p>
                             <b-skeleton   v-if="loading" width="100%" style="margin-top:10px;margin-bottom:0px" height="40px"></b-skeleton>
                                     <b-form-input
                                     v-else
                                         
                                        v-model="acreditado"
                                        placeholder="$0.00"
                                        type="text"
                                        :readonly="true"
                
                                        />
                        </b-col>

                        <b-col
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          style="margin-top:15px"
                        >

                          <b-alert
                            variant="primary"
                            show
                            v-if="isTarjeta"
                          >
                            <div class="alert-body" style="text-align: center;">
                              <span> Se aplican comisiones de {{comision}} % más ${{transaccion}} por transacción</span>
                            </div>
                          </b-alert>

                          <b-alert
                            variant="primary"
                            show
                            v-if="isWester"
                          >
                            <div class="alert-body" style="text-align: center;">
                              <span> No se aplican comisiones adicionales</span>
                            </div>
                          </b-alert>

                          <b-alert
                            variant="primary"
                            show
                            v-if="isDeposito"
                          >
                            <div class="alert-body" style="text-align: center;">
                              <p> No se aplican comisiones adicionales.</p>
                              <p>Con transferencias bancarias no existe monto mínimo ni máximo para recargar la billetera de EQCoop.</p>
                            </div>
                          </b-alert>

                          <b-alert
                            variant="primary"
                            show
                            v-if="isPaypal"
                          >
                            <div class="alert-body" style="text-align: center;">
                              <span> Se aplican comisiones de {{comisionPaypal}} % más ${{comisionTransaccionPaypal}} por transacción <br> Aplica comisión de EQCoop del {{comisionEQCoopPaypal}}% </span>
                            </div>
                          </b-alert>

                          

                        </b-col>

                            

                        
                    </b-row>

                 


</template>

<script>
import {
  BButton, BSpinner, BRow, BCol, BCard, BFormInput, BFormSelect,VBPopover,BSkeleton, BAlert
} from 'bootstrap-vue'



export default {
  components: {
    BButton,
    BAlert,
    BSkeleton,
    BRow,
    BCol,
    BCard,
    BSpinner,
    BFormSelect,
    BFormInput,
  },
    directives: {
    'b-popover': VBPopover,
  },
  props: ['userId','tokenAuth'],
  data() {


    return {

      loading: true,
      isTarjeta: false,
      isWester: false,
      isDeposito:false,
      isPaypal: false,
      comision:0,
      transaccion:0,
      comisionWester:0,
      comisionEQCoopPaypal:0,
      comisionTransaccionPaypal:0,
      comisionPaypal:0,
      formaRecarga:null,
      mountRecarga:null,
      acreditado:"$0.00",
       isDisabledMount:true,
      options1: [
        { value: null, text: 'Seleccione una opción' },
        { value: 'tarjeta', text: 'Tarjeta de crédito o débito' },
        { value: 'deposito', text: 'Transferencia bancaria' },

      ],     options2: [
        { value: null, text: 'Seleccione una opción' },
        { value: '5', text: 'Recarga $5.00' },
        { value: '15', text: 'Recarga $15.00' },
         { value: '25', text: 'Recarga $25.00' },
          { value: '50', text: 'Recarga $50.00' },
           { value: '75', text: 'Recarga $75.00' },
            { value: '100', text: 'Recarga $100.00' },

      ],



    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {

    this.loadCalculadora();

       



   
  },
  methods: {

    loadCalculadora(){

 

      this.$https.post('/transactions/fee/', { tokenAuth: this.tokenAuth, userId: this.userId }).then(response => {
         this.loading=false;

     
 
                      if(response.data["code"] == 200){
                      
                        

                        this.comisionWester=response.data["comisionWester"];
                         this.comision=response.data["comision"];
                          this.transaccion=response.data["transaccion"];
                          this.comisionEQCoopPaypal=response.data["comisionEQCoopPaypal"];
                          this.comisionPaypal=response.data["comisionPaypal"];
                          this.comisionTransaccionPaypal=response.data["comisionTransaccionPaypal"];
           
                          
                        }else{

                       


                                  if (response.data.code == 401) {

                                    this.$toast.error(response.data.message, {
                                          position: 'top-right',
                                          timeout: 3010,
                                          closeOnClick: true,
                                          pauseOnFocusLoss: false,
                                          pauseOnHover: true,
                                          draggable: true,
                                          draggablePercent: 0.4,
                                          showCloseButtonOnHover: false,
                                          hideProgressBar: true,
                                          closeButton: 'button',
                                          icon: true,
                                          rtl: false,
                                        })

                                    
                                    localStorage.removeItem('userData')



                                    
                                    

           

                                    
                                    

                                    // Redirect to login page
                                    this.$router.push({ name: 'auth-login' })
                                  } else {

                

                                    
                                    this.loadCalculadora();
                                  


                                  }

                        }
                 }).catch((error) => {
                  this.loadCalculadora();

                     
                 });

    },

        onChangeFormaRecarga(){




        
            if(this.formaRecarga === "tarjeta"){

              this.isTarjeta=true;
                    this.isWester=false;
                    this.isPaypal=false;
                    this.isDeposito=false;

                    if(this.mountRecarga == null){

                      this.isDisabledMount=false;


                      }else{

                        
                   var porcentaje=  (Number(this.mountRecarga) * this.comision )/100;

                      var acreditada_billetera = (Number(this.mountRecarga) - Number(porcentaje) - Number(this.transaccion));
                      this.acreditado= "$"+acreditada_billetera.toFixed(2);

                      }




                  


                }else{

                  if(this.formaRecarga === "wester"){

                    this.isTarjeta=false;
                    this.isWester=true;
                    this.isDeposito=false;
                    this.isPaypal=false;


                    if(this.mountRecarga == null){

                        this.isDisabledMount=false;





                        }else{

                          var acreditada_billetera = (Number(this.mountRecarga) - Number(this.comisionWester));

                        this.acreditado= "$"+acreditada_billetera.toFixed(2);

                        }


    

                  }else{

                    if(this.formaRecarga === "deposito"){

           
                      this.isDeposito=true;
                    this.isTarjeta=false;
                    this.isWester=false;
                    this.isPaypal=false;


                    if(this.mountRecarga == null){

                        this.isDisabledMount=false;





                        }else{

            
                          var acreditada_billetera = (Number(this.mountRecarga) - Number(this.comisionWester));

                        this.acreditado= "$"+acreditada_billetera.toFixed(2);

                        }


                    }else{

                      if(this.formaRecarga === "paypal"){

                        this.isTarjeta=false;
                        this.isWester=false;
                        this.isDeposito=false;
                        this.isPaypal=true;

                        if(this.mountRecarga == null){

                            this.isDisabledMount=false;





                        }else{

          


                          var porcentaje_eqpay= ((Number(this.mountRecarga) * Number(this.comisionEQCoopPaypal))/100); 


                          var porcentaje_final= ((Number(this.mountRecarga) * Number(this.comisionPaypal))/100)+Number(this.comisionTransaccionPaypal)+Number(porcentaje_eqpay); 




                          var suma= (Number(this.mountRecarga) - porcentaje_final);




                          this.acreditado= "$"+suma.toFixed(2);

      

                        }

                      }

                    }

                  

                 
                  }

        


                }

        

   
   
             

            

    },

    onChangeMount(){



       if(this.formaRecarga === "tarjeta"){

                   var porcentaje=  (Number(this.mountRecarga) * this.comision )/100;

                    var acreditada_billetera = (Number(this.mountRecarga) - Number(porcentaje) - Number(this.transaccion));
                    this.acreditado= "$"+acreditada_billetera.toFixed(2);


                }else{

                  if(this.formaRecarga === "wester"){

                    var acreditada_billetera = (Number(this.mountRecarga) - Number(this.comisionWester));

                      this.acreditado= "$"+acreditada_billetera.toFixed(2);

                  }else{

                    if(this.formaRecarga === "deposito"){

                      var acreditada_billetera = (Number(this.mountRecarga) - Number(this.comisionWester));

                      this.acreditado= "$"+acreditada_billetera.toFixed(2);

                    }else{

                      if(this.formaRecarga === "paypal"){

                        var porcentaje_eqpay= ((Number(this.mountRecarga) * Number(this.comisionEQCoopPaypal))/100); 

                  
                    var porcentaje_final= ((Number(this.mountRecarga) * Number(this.comisionPaypal))/100)+Number(this.comisionTransaccionPaypal)+Number(porcentaje_eqpay); 



                    var suma= (Number(this.mountRecarga) - porcentaje_final);




                    this.acreditado= "$"+suma.toFixed(2);


                      }

                    }
              

                  }




                }
  
    },

  },
}
</script>

<style lang="scss">



</style>
