<template>

<div>
        <modal-recarga-paypal v-if="openModalPaypal" :key="componentKeyModalRecargaPaypal"  :user-id="userId" :token-auth="tokenAuth"  />
     
        
        <b-card id="paypal" class="card_opciones" block @click="paypal">

                <b-row>
                  <b-col cols="3" sm="3" md="3" lg="12" xl="12" align="center" style="display: flex;" >

                <b-avatar
                        size="40"
                        variant="light-primary"
                        style="margin:auto;">

                        <span class="d-flex align-items-center">
                            <svg class="icons_colors3"   viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg"><title>PayPal icon</title><path d="M6.908 24H3.804c-.664.0-1.086-.529-.936-1.18l.149-.674h2.071c.666.0 1.336-.533 1.482-1.182l1.064-4.592c.15-.648.816-1.18 1.48-1.18h.883c3.789.0 6.734-.779 8.84-2.34s3.16-3.6 3.16-6.135c0-1.125-.195-2.055-.588-2.789.0-.016-.016-.031-.016-.046l.135.075c.75.465 1.32 1.064 1.711 1.814.404.75.598 1.68.598 2.791.0 2.535-1.049 4.574-3.164 6.135-2.1 1.545-5.055 2.324-8.834 2.324h-.9c-.66.0-1.334.525-1.484 1.186L8.39 22.812c-.149.645-.81 1.17-1.47 1.17L6.908 24zm-2.677-2.695H1.126c-.663.0-1.084-.529-.936-1.18L4.563 1.182C4.714.529 5.378.0 6.044.0h6.465c1.395.0 2.609.098 3.648.289 1.035.189 1.92.519 2.684.99.736.465 1.322 1.072 1.697 1.818.389.748.584 1.68.584 2.797.0 2.535-1.051 4.574-3.164 6.119-2.1 1.561-5.056 2.326-8.836 2.326h-.883c-.66.0-1.328.524-1.478 1.169L5.7 20.097c-.149.646-.817 1.172-1.485 1.172l.016.036zm7.446-17.369h-1.014c-.666.0-1.332.529-1.48 1.178l-.93 4.02c-.15.648.27 1.179.93 1.179h.766c1.664.0 2.97-.343 3.9-1.021.929-.686 1.395-1.654 1.395-2.912.0-.83-.301-1.445-.9-1.84-.6-.404-1.5-.605-2.686-.605l.019.001z"/></svg>


                        </span>

                        </b-avatar>





                    </b-col>

                    <b-col cols="9" sm="9" md="9" lg="12" xl="12" >

                        <p class="p1_opciones">Con saldo PayPal </p>  
                        <p class="p2_opciones">Trae tu dinero a EQCoop </p>  


                    </b-col>
                </b-row>

                </b-card>
      

  </div>
  </template>
  
  <script>
  import {
    BButton, BRow, BCol, BCard, BAvatar
  } from 'bootstrap-vue'
  import ModalRecargaPaypal from './ModalRecargaPaypal.vue'

  export default {
    components: {
        BButton,
        BAvatar,
    BRow,
    BCol,
    BCard,
    ModalRecargaPaypal,

    },
    directives: {
    
    },
    props: ['userId', 'tokenAuth'],
    data() {

    

      return {
        openModalPaypal:false,
  
        componentKeyModalRecargaPaypal:0,
      }
    },
    computed: {
  
    },
    watch: {
  
  
    },
    mounted() {

     
    this.$eventBus.$on('reiniciarRecargasPayPal', () => {
      this.componentKeyModalRecargaPaypal += 1
      this.openModalPaypal=false;
    });
      
    },
    methods: {
  
        paypal(){

this.openModalPaypal=true;



},
  
    },
  }
  </script>
  
  <style lang="scss">
  
  


  </style>
  