<template>

<div>
    <modal-recarga-tarjetas v-if="openModalTarjetas" :key="componentKeyModalRecargaTarjetas" :cards="cards"  :user-id="userId" :token-auth="tokenAuth"  />

    <b-card  class="card_opciones" block @click="listCards">

            <b-row>
              <b-col cols="3" sm="3" md="3" lg="12" xl="12" align="center" style="display: flex;" >

                <b-avatar
                    size="40"
                    variant="light-primary"
                    style="margin:auto;">

                    <span class="d-flex align-items-center">
                        <feather-icon
                                icon="CreditCardIcon"
                                size="25"

                                class=" icon_opciones2"
                                />

                    </span>

                    </b-avatar> 


                    </b-col>

                    <b-col cols="9" sm="9" md="9" lg="12" xl="12" >

                        <p class="p1_opciones">Con tarjetas de pagos </p>  
                        <p class="p2_opciones">Fácil, rápido y seguro </p>  


                    </b-col>
            </b-row>

            </b-card>

  </div>
  </template>
  
  <script>
  import {
    BButton, BRow, BCol, BCard,BAvatar
  } from 'bootstrap-vue'
  import ModalRecargaTarjetas from './ModalRecargaTarjetas.vue'


  export default {
    components: {
        BButton,
        BAvatar,
    BRow,
    BCol,
    BCard,
    ModalRecargaTarjetas
    },
    directives: {
    
    },
    props: ['userId', 'tokenAuth'],
    data() {

    

      return {
      
        openModalTarjetas:false,
        componentKeyModalRecargaTarjetas:0,
        cards: []
      
      }
    },
    computed: {
  
    },
    watch: {
  
  
    },
    mounted() {
        this.$eventBus.$on('reiniciarRecargasTarjetas', () => {
      this.componentKeyModalRecargaTarjetas += 1
      this.openModalTarjetas=false;
    });

    
      
    },
    methods: {

 
        listCards(){

if(Number(this.cards.length) === Number("0")){

  this.$swal({

    html:
    '<img style="margin-left:10px; width: 30px" src="/img/loading.svg" alt="Loading" /> Obteniendo tus tarjetas',
    showCloseButton: false,
    showCancelButton: false,
    focusConfirm: false,
    allowEscapeKey: false,
    allowOutsideClick: false,
    showConfirmButton: false,
    position: 'center',
    })





    const dataEncripted = this.$encryptBackoffice.encrypt(this.userId)

    this.$https.post('/card/listCardsVerify/', { tokenAuth: this.tokenAuth, userId: dataEncripted }).then(response => {
 
    this.$swal.close()


    
          if(response.data["code"] == 200){

            this.cards= response.data["cards"];

            this.openModalTarjetas=true;

              
   

              
            }else{

             
            
                if (response.data.code == 401) {

                      this.$toast.error(response.data.message, {
                      position: 'top-right',
                      timeout: 3010,
                      closeOnClick: true,
                      pauseOnFocusLoss: false,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 0.4,
                      showCloseButtonOnHover: false,
                      hideProgressBar: true,
                      closeButton: 'button',
                      icon: true,
                      rtl: false,
                    })
                        
                        localStorage.removeItem('userData')



                        

                        
                        

                        // Redirect to login page
                        this.$router.push({ name: 'auth-login' })
                      }else{

                          if (response.data.code == 404) {

                                this.$swal({
                                    title: 'No tiene tarjetas de pagos registrados',
                                    text: `¿Deseas agregar una tarjeta de pago ahora?`,
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonText: 'Registrar',
                                    cancelButtonText: 'No',
                                    customClass: {
                                      confirmButton: 'btn btn-primary',
                                      cancelButton: 'btn btn-outline-secondary ml-1',
                                    },
                                    buttonsStyling: false,
                                  }).then(result => {
                                    if (result.value) {

                                      this.$router.push({ name: 'perfil', hash: '#tarjetas' })
                        
                                    }
                                  })

                          }else{
                              this.$toast.error(response.data.message, {
                              position: 'top-right',
                              timeout: 3010,
                              closeOnClick: true,
                              pauseOnFocusLoss: false,
                              pauseOnHover: true,
                              draggable: true,
                              draggablePercent: 0.4,
                              showCloseButtonOnHover: false,
                              hideProgressBar: true,
                              closeButton: 'button',
                              icon: true,
                              rtl: false,
                            })
                          }

                        

                      } 

            }
      }).catch((error) => {

                this.code200=false;

          this.$toast.error("Ocurrió un error inesperado", {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })

          
      });

}else{

  this.openModalTarjetas=true;

}


},
      
  
    },
  }
  </script>
  
  <style lang="scss">
  
  


  </style>
  