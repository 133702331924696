<template>

<div>
    <modal-recarga-banco v-if="openModalRecargarBanco" :banks="banks" :key="componentKeyModalRecargaBanco"  :user-id="userId" :token-auth="tokenAuth"  />
       
    <b-card class="card_opciones" block @click="banco" style="    margin: 0;">

            <b-row>
              <b-col cols="3" sm="3" md="3" lg="12" xl="12" align="center" style="display: flex;" >

            <b-avatar
                    size="40"
                    variant="light-primary"
                    style="margin:auto;">

                    <span class="d-flex align-items-center">
                        <svg class="icons_colors3"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 33.646 33.646"><g><path d="M18.008 9.985c.14.094.207.285.207.577.0.532-.312.799-.933.799h-.14V9.844c.439.0.727.0470000000000006.866.141zM15.492 8.25c0 .476.285.714.854.714.006.0.055.002.144.008V7.568l-.14.005C15.779 7.571 15.492 7.798 15.492 8.25zM27.99 26.906h3.666v3H1.99v-3h3.666V14.741H0L16.823 3.74l16.823 11.001H27.99V26.906zM15.323 14.741H8.656v12.165h6.667V14.741zm2.021-2.557c.685.0 1.164-.121 1.438-.365.273-.243.412-.669.412-1.273.0-.562-.126-.947-.378-1.158-.253-.211-.737-.336-1.456-.377l-.217-.008V7.571h.15c.531.0.795.212.795.636l.006.104h.936V8.178c0-.562-.135-.946-.398-1.153-.269-.206-.764-.31-1.486-.31V6.157h-.652v.558c-.758.0-1.275.107-1.557.324-.279.215-.42.616-.42 1.202.0.604.14 1.019.418 1.238.277.22.799.329 1.559.329v1.552l-.143-.004c-.392.0-.643-.052-.756-.153-.11-.102-.168-.329-.168-.682v-.1h-.967l-.004.194c0 .578.139.983.416 1.218.276.232.756.35 1.438.35l.184.005v.641h.652v-.641L17.344 12.184zm7.646 2.557h-6.667v12.165h6.667V14.741z"/></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>


                    </span>

                    </b-avatar> 

                </b-col>

                <b-col cols="9" sm="9" md="9" lg="12" xl="12" >

                    <p class="p1_opciones">Con transferencia bancaria</p>  
                    <p class="p2_opciones">Utilizando una cuenta bancaria</p>  


                </b-col>
            </b-row>

            </b-card>
          </div>

  
  </template>
  
  <script>
  import {
    BButton, BRow, BCol, BCard,BAvatar
  } from 'bootstrap-vue'
  import ModalRecargaBanco from './ModalRecargaBanco.vue'

  export default {
    components: {
        BButton,
        BAvatar,
    BRow,
    BCol,
    BCard,

    ModalRecargaBanco,
    },
    directives: {
    
    },
    props: ['userId', 'tokenAuth'],
    data() {

    

      return {
        openModalRecargarBanco:false,
        componentKeyModalRecargaBanco:0,
        banks:[],
  
      }
    },
    computed: {
  
    },
    watch: {
  
  
    },
    mounted() {

        this.$eventBus.$on('reiniciarRecargasBanco', () => {
      this.componentKeyModalRecargaBanco += 1
      this.openModalRecargarBanco=false;
    });

      
    },
    methods: {
        banco(){

      
if(Number(this.banks.length) === Number("0")){

        this.$swal({

          html:
          '<img style="margin-left:10px; width: 30px" src="/img/loading.svg" alt="Loading" /> Cargando bancos, espere',
          showCloseButton: false,
          showCancelButton: false,
          focusConfirm: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          position: 'center',
          })



  

          this.$https.post('/profile/searchBancosDepositos/', { tokenAuth: this.tokenAuth, userId: this.userId }).then(response => {

        


          
                if(response.data["code"] == 200){
                  this.$swal.close()
                  this.banks= response.data["bancos"];
                  this.openModalRecargarBanco= true;

               
                    


                    
                  }else{

              
                      if (response.data.code == 401) {
                        this.$swal.close()
                        this.$toast.error(response.data.message, {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                      })
                  
                              
                              localStorage.removeItem('userData')

                          
                        
                              

                              
                              

                              // Redirect to login page
                              this.$router.push({ name: 'auth-login' })
                            }else{

                              if(response.data.code === 406){

                               
                                this.$swal.close()
                                this.$swal({
                                    title: 'No tienes registrado una cédula/DNI a tu perfil',
                                    text: `¿Deseas completar tu perfil ahora?`,
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonText: 'Registrar',
                                    cancelButtonText: 'No',
                                    customClass: {
                                      confirmButton: 'btn btn-primary',
                                      cancelButton: 'btn btn-outline-secondary ml-1',
                                    },
                                    buttonsStyling: false,
                                  }).then(result => {
                                    if (result.value) {
                                      this.$router.push({ name: 'perfil', hash: '#general' })
                        
                                    }
                                  })


                              }else{
                                this.banco();
                              }
                            
                            
                            } 

                  }
            }).catch((error) => {

              this.banco();
                
            });

        }else{

          this.openModalRecargarBanco= true;



        }



},
      
  
    },
  }
  </script>
  
  <style lang="scss">
  
  


  </style>
  